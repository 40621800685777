import * as styles from "../Login/sign-in-page.module.css";

import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useState } from "react";

import SEND_PASSWORD_RESET_EMAIL from "../hooks/resetPassword";
import { useMutation } from "@apollo/client";

const SendPasswordResetEmailForm: React.FC = () => {
  const [sendPasswordResetEmail, { error, loading }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL
  );
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const toast = useToast();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendPasswordResetEmail({
      variables: {
        username: email,
      },
      onCompleted: () => {
        setEmailSent(true);
        toast({
          title: "Email Sent",
          description: "Check your email for the password reset link.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }).catch(() => {
      toast({
        title: "Error",
        description: "User with that email addres doesn't exist.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
  };

  return (
    <div className={styles.signInPage}>
      <div className={styles.registerSection}>
        <div className={styles.registerContent}>
          <div className={styles.contactForm}>
            <form method="post" onSubmit={handleSubmit}>
              <div className={styles.contactFormInputs}>
                <VStack alignItems="flex-start" pb="16px">
                  <Text fontSize="30px" className={styles.display}>
                    Reset Your TCP Supply Password Easily
                  </Text>
                  <Text>
                    Please enter your email address. You will receive a link to
                    create a new password via email.
                  </Text>
                </VStack>
                <div className={styles.inputRow}>
                  <div className={styles.inputFieldWIthLabel}>
                    <div className={styles.inputLabel}>
                      <div className={styles.email}>Email</div>
                    </div>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      required
                      variant="outline"
                      textColor="#1a202c"
                      borderColor="#e2e8f0"
                      focusBorderColor="#3182ce"
                      placeholder="johndoe@email.com"
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      value={email}
                    />
                  </div>
                </div>
                <Button variant="solid" type="submit">
                  {loading ? "Loading..." : "Reset Password"}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <img
          className={styles.contactDetailsIcon}
          alt=""
          src="/contactdetails@2x.png"
        />
      </div>
    </div>
  );
};

export default SendPasswordResetEmailForm;
